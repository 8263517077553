
.mini-file-upload{
	[type="file"]{
		display: none;
	}
}

.file-browser{
	a{
		text-decoration: none;
	}
	.file-preview img{
		max-width: 100%;
		max-height: 120px;
	}
	.bin{
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-around;
		justify-content: flex-start;
		align-items: stretch;
		align-content: stretch;

		> *{
			flex: 0 1 200px;
			margin: .5em;
			padding: 6px 8px;
			border-radius: 4px;
			background: rgba(0, 0, 0, .05);

			display: flex;
			align-items: center;
		}
	}

	.file-loading{
		text-align: center;
		padding: 20vh 16px;
		background: rgba(0,0,0,.05);
		box-shadow: inset 0 0 3vh 3vh white;
		display: none;

		&.visible{
			display: block;
		}
		.fa{
			font-size: 2em;
		}
	}
}

.file-preview{
	text-align: center;

	img{
		max-width: 100%;
		max-height: 100vh;
	}

	.fas, .far{
		font-size: 3.2em;
		margin: 15px 0 8px;
	}
}

.file-item{
	.preview{
		width: 100%;
		text-align: center;
	}
	.metas{
		padding: 3px 0;
		font-size: 10px;
		line-height: 12px;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		color: rgba(0,0,0,.6);
	}
}

