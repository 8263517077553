
.full-link-type{

	.level-indicator{
		border-right: 1px solid #dee2e6;
		transition: all .15s ease-out;
		//background-color: #dee2e6 !important;
		//background-color: #ecf1f7 !important;
		background-color: #f7f7f7 !important;
		position: relative;

		&::before{
			content: "";
			position: absolute;
			top: calc(50% - 12px);
			pointer-events: none;
			opacity: 0;
			//color: white;
			color: #cbcbcb;
		}
	}
	&[data-level="0"] .level-indicator{
		flex-basis: 0;
		border-color: transparent;
	}
	&[data-level="1"] .level-indicator{
		flex-basis: 30px;
		&::before{
			content: "▶";
			opacity: 1;
			right: calc(50% - 8px);
		}
	}
	&[data-level="2"] .level-indicator{
		flex-basis: 60px;
		&::before{
			content: "▶▶";
			opacity: 1;
			right: calc(50% - 16px);
		}
	}



	&.is-disabled{
		user-select: none;
		button:not(.active-when-disabled), input, select, label{
			opacity: .5;
			pointer-events: none;
		}
	}
}
