
#main_nav{

	> a{
		display: block;
		transition: all .05s linear;
	}

	> a:hover, > a:focus{
		color: $app_orange;
		//background: rgba(0,0,0,.05);

		[class^="fa-"]{
			color: $app_orange;
		}
	}
	> a.active{
		color: $app_orange;
		//background: rgba(0,0,0,.1);
	}

	> a.locale-link{
		font-size: small;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	> a.locale-link:hover, > a.locale-link:focus{
		background: transparent;
	}
	> a.locale-link.active{
		background: transparent;
		font-weight: bold;
	}

	.fa, .fas, .far{
		width: 24px;
		text-align: center;
	}

	.collapsable{
		background-color: rgba(0,0,0,.7);
	}
	.collapsable a,
	.secondary a{
		padding: 9px $grid-gutter-width /2 9px 30px;
		font-size: .9em;
	}
}
