
#minimal_layout{

	#minimal_layout_background{
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;

		.waves-container, .canvas{
			width: 100%;
			height: 100%;
		}
	}

	#minimal_layout_content{
		position: relative;
		z-index: 50;
		min-height: 100vh;
		outline: 2px solid red;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;

		.logo{
			max-width: 300px;
			margin: $grid-gutter-width auto;

			*{
				fill: white;
			}
		}

		> *{
			margin: 30px;
			min-width: 300px;
			max-width: 600px;
		}
	}
}
