
#side{
	background-color: $body-bg;
}

#side_header{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-weight: 400;
	padding: 16px $grid-gutter-width;

	img, svg{
		max-width: 100%;
	}
	path{
		fill: $primary;
		transition: all .1s ease-out;
	}
	a:hover, a:focus{
		svg path{
			//fill: darken($primary, 10);
			//fill: $app_purple;
			fill: $app_orange;
		}
	}

}
