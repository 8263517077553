$disabled_color_1: rgba(255, 255, 255, 0.9);
$disabled_color_2: rgba(238, 238, 238, 0.8);

// Page blocks
#page_currentContent_blocks{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	.disabled-layer{
		position: absolute;
		z-index: 600;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all .35s ease-out;
		opacity: 0;
		pointer-events: none;
		background-image: linear-gradient(45deg,
				$disabled_color_1 12.82%,
				$disabled_color_2 12.82%,
				$disabled_color_2 50%,
				$disabled_color_1 50%,
				$disabled_color_1 62.82%,
				$disabled_color_2 62.82%,
				$disabled_color_2 100%
		);
		background-size: 24.04px 24.04px;
	}
	>*.disabled{
		.if-enabled{
			display: none;
		}
		.disabled-layer{
			opacity: 1;
			pointer-events: all;
		}
	}>*.enabled{
		.if-disabled{
			display: none;
		}
	}
}


// #########
// ADD A PAGE BLOCK
// #########

.page-block-add-toolbar{
	background: $card-cap-bg;
	position: relative;
	min-height: 1px;
	text-align: center;

	a .icon{
		svg{
			height: 1em;
			*{
				fill: currentColor;
			}
		}
	}

	a[data-action="collapseNext"]{
		position: absolute;
		display: inline-block;
		margin-top: -1rem;

		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	[data-action="addPageBlock"]{
		display: inline-block;
		margin: auto;
	}

}

.page-block-widget-wrapper{
	&.card{
		border-radius: 0;
		border-left: 0;
		border-right: 0;
	}

	.block-meta-fields{
		display: none; // DEV : toggle
	}
}


// #########
// PAGE BLOCKS
// #########

.page-block-widget-wrapper{
	.block-title-link{
		text-decoration: none;
		color: inherit;
		display: inline-block;
		.fa{
			transform: rotate(0);
			transition: all .15s;
		}
		&:hover, &:focus{
			.fa{
				color: orange;
			}
		}
	}
	&.wrapped{
		.block-title-link .fa{
			transform: rotate(-90deg);
			color: orange;
		}
	}

	.page-block-widget-wrapper-toolbar{
		svg{
			height: 1em;
			*{
				fill: currentColor;
			}
		}
	}

	b{
		font-weight: 700;
	}

	// Closing blocks
	.if-closing-block-inline,
	.if-closing-block-block{
		display: none;
	}

	&.is-closing-block{
		.if-closing-block-inline{
			display: inline;
		}
		.if-closing-block-block{
			display: block;
		}

		.card-body, .wrap-caret,
		[data-action="removeFromCollection"],
		[data-action="toggleContentBlock"]
		{
			display: none;
		}
		[data-action="wrapToggleContentBlock"]
		{
			cursor: default;
			pointer-events: none;
		}
	}
}

// BLOCK TEXT
.page-block-widget-scene[data-type="text"]{
	.tox-statusbar{
		display: none
	}
	.tox-tinymce{
		border: 0;
	}
}
