// MISE EN PAGE
html{
	height: 100%;
}
body{
	font-family: 'Open Sans', sans-serif;
	height: 100%;

	&.frame-body{
		background: white;
	}
}
h1, h2, h3, b{
	font-weight: 400;
}

#main{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	min-height: 100%;
	min-width: 100%;
}
#side{
	flex-basis: $nav_w;
	flex-shrink: 0;
	overflow: auto;
	position:relative;
	z-index: 901;
	transition: margin $sideTransition;
	//box-shadow: inset -10px 0px 21px -14px rgba(0,0,0,0.3);

	// Rattrapage du contenu main sur la gauche
	@include media-breakpoint-down(lg) {
		margin-left: -1 * $nav_w;
		box-shadow: none;
	}
}

#main_content{
	flex-grow: 1;
	flex-shrink: 1;
	overflow: auto;
	//background: #fcfcfc;
	position: relative;
	z-index: 400;

	> .container-fluid{
		padding-left: $containerGutter;
		padding-right: $containerGutter;
		margin-left: 0;
		transition: margin $sideTransition, width $sideTransition;
	}
}
#main_content_side_overlay{
	position: fixed;
	z-index: 900;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: $shader-bg;
	opacity: 0;
	pointer-events: none;
	transition: opacity $sideTransition;
}

.frame-vertical-centering{
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
}

// Toggle side
body.openSide{
	#side{
		margin-left: 0;
	}
	#main_content > .container-fluid{
		margin-left: -1 * $nav_w;
		width: calc(100% + #{$nav_w});
	}
	#main_content_side_overlay{
		opacity: 1;
		pointer-events: all;
	}
}
@include media-breakpoint-up(lg) {
	.side-toggler{
		display: none;
	}
}

// Disabled side
body.disableSide{
	#side{
		margin-left: -1 * $nav_w;
	}
	[data-action=disableSide]{
		display: none;
	}
	[data-action=enableSide]{
		display: inline-block;
	}
}
@include media-breakpoint-down(lg) {
	[data-action=disableSide], [data-action=enableSide]{
		display: none;
	}
}
[data-action=enableSide]{
	display: none;
}



// TOOLS

.smallblock{
	display: block;
	font-size: small;
	line-height: 1.2em;
}
.smaller{
	font-size: 10px;
}

.text-ucfirst:first-letter{
	text-transform: uppercase;
}

.pipe-separated >*:after{
	content: " | ";
}
.pipe-separated >*:last-child:after{
	content: "";
}
.text-inherit{
	color: inherit;
}
.nowrap{
	white-space: nowrap;
}
.line-separated{
	>*:not(:last-child)::after{
		content: "-";
		display: inline-block;
		padding: 0 3px 0 5px;
		text-decoration: none;
	}
}

.draggable-handle{
	display: flex;
	justify-content: center;
	align-items: center;
	background: $gray-100;
	cursor: grab;
}

.comma-separated{
	>*:not(:last-child)::after{
		content: ', ';
	}
}
