
.waves-container{

	//opacity:0.5;
	//background-position: center;
	//background-repeat: no-repeat;
	//background-attachment: fixed;
	//font-family: "FF Meta VF", "PT Sans", Helvetica, Arial, sans-serif;
	//font-weight: 100;
	position: relative;
	z-index: 10;

	canvas{
		background: $app_darkblue;
		min-height: 300px;
		animation: bg-colour 60s linear infinite;
		height: 100vh;
		width: 100vw;
	}
}

@keyframes bg-colour{
	0%{
		background: $app_darkblue
	}
	25%{
		background: $app_lightblue
	}
	50%{
		background: $app_orange
	}
	75%{
		background: $app_purple
	}
	100%{
		background: $app_darkblue
	}
}
