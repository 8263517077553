
.raw-image-type-preview-wrapper{
	margin-bottom: 1rem;
	width: 100%;
	position:relative;
	padding-top:calc((1/(16/9))*100%);
}

.raw-image-type-preview{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(100,100,100,.05);
	border: 3px solid rgba(100,100,100,.05);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	cursor: pointer;
}

.raw-image-type-preview-message{
	opacity: .8;
	font-style: italic;
	text-align: center;
	padding: .2em;
}

.raw-image-type-clear-wrapper{
	position: absolute;
	top:3px;
	right:3px;
}

.raw-image-type-clear-wrapper button{
	cursor: pointer;
	color: #000;
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
	background-color: #f8f9fa;
	border: 1px solid #f8f9fa;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	transition: all .15s;
}
.raw-image-type-clear-wrapper button:hover, .raw-image-type-clear-wrapper button:focus{
	background-color: #ececec;
}
.raw-image-type-clear-wrapper svg{
	display: inline-block;
	height: 1.1rem;
}

.raw-image-metas-wrapper:not(.debug){
	position: absolute;
	pointer-events: none;
	opacity: 0;
}
