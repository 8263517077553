
.dataTables_scrollBody{
	overflow: visible !important; // debug popovers
}
.dataTables_paginate{
	margin-top: 20px !important;
}
table.dataTable{
	td{
		vertical-align: middle;

		a:not(.btn){
			text-decoration: none;
			//font-weight: bold;
		}
	}
	&.row-selectable{
		tr{
			cursor: pointer;

			&:hover{
				td{
					background-color: rgba(18, 100, 100, 0.02);
				}
			}
		}
	}
}
