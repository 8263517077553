#main_header{
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	//background: $secondary;
	//color: $gray-100;

	min-height: $header-h;
	margin-left: $containerGutter * -1;
	margin-right: $containerGutter * -1;
	padding-left: $containerGutter;
	padding-right: $containerGutter;

	.side-toggler{
		font-size: 32px;
		margin: 2px 15px 0 -6px;
	}

	h1{
		//font-size: 27px;
		//padding: 14px 0 5px 0;
	}

	a{
		color: inherit;
	}

	//.right{
	//	margin-left: auto;
	//}
}
