
.raw-image-upload-widget{
	margin: 1rem 0;

	.image-btn{
		border: none;
		display: block;
		width: 100%;
		position: relative;
		padding-top: calc(100% / (16/9));
		background-color: #e5e5e5;
		cursor: pointer;
		transition: all .2s ease-out;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		&.empty-image{
			background-image: none !important;
			box-shadow: 0 0 15px inset rgba(192, 192, 192, 0.4);

			&:hover{
				background-color: #d5d5d5;
				box-shadow: 0 0 15px inset silver;
			}
		}
	}
	.empty-image:after{
		display: block;
		position: absolute;
		text-align: center;
		left: 0;
		font-size: 14px;
		top: calc(50% - 7px);
		content: "No image";
		width: 100%;
		color: rgba(0,0,0,.8);
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	}
	input[type="file"], canvas{
		display: none;
	}
}

.raw-image-upload-modal{
	.modal-body{
		height: 80vh;
	}
	.raw-image-upload-spinner{
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
	}
	.raw-image-upload-preview{
		display: flex;
		height: 100%;
		justify-content: center;
		//align-items: stretch;
		align-items: center;

		> :first-child{
			align-self: stretch;
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 50vw;

			//background-size: contain;
			//background-repeat: no-repeat;
			//background-position: center;

			display: flex;
			justify-content: center;
			align-items: center;
		}
		> :last-child{
			margin-left: 1rem;
			flex-basis: 200px;
		}
	}

	.raw-image-upload-preview-image{
		max-height: 100%;
		max-width: 100%;
	}
}
















